<template >
  <div class="container">
    <div class="mt-5 ml-8">
      <h3 class="mt-5 P-Text ">{{ $t("RateYourExperience") }}</h3>
      <h3>{{$t('With')}}</h3>
    </div>
    <div class="logo text-center">
    
         <imageLazy
                      :imageStyle="{ 'border-radius': '20px','width':'100px'}"
                      :imageClass="'doctorImage'"
                      :src="baseURL + doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
    </div>

    <div class="w-full text-center ">
      <div style="">
        <p>{{ $t("Dr") }} . {{ doctor.Name }}</p>
        <span v-if="doctor.City">{{ doctor.City.Name }}</span> , <span v-if="doctor.Country"> {{ doctor.Country.Name }} </span>
        <br />
        <div>
          <star-rating
            class="text-center"
            @rating-selected="setRating"
            style="margin-top:2rem"
          > 
          </star-rating>
        </div>
        <div>
          <textarea
            :placeholder="$t('Type Your Review ...')"
            class="mt-5 w-full commentClass text-left "
            name="nameen"
            v-validate="'required'"
            v-model="model.Comment"
          />
          <span class="text-danger  text-sm" v-show="errors.has('nameen')">{{
            errors.first("name_en")
          }}</span>
          <br /><br />
        </div>
        <div>
          <vs-button class="w-full vsButton" @click="submitData()">
            <p>{{ $t("Rate") }}</p>
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import StarRating from "vue-star-rating";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  data() {
    return {
      rating: 0,
      PhotoPath: "",
      baseURL: domain,
      model:{
         DoctorID:0,
        PatientReserviedSessionID:0,
        Comment:""
      },
      doctor: {
        
      }
    };
  },
  components: {
    StarRating,
    imageLazy
  },
  props: {
    DoctorRate: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    submitData() {

      this.model.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$store
            .dispatch("DoctorList/AddDoctorUserReview", this.model).then(res=>{
                if(this.$route.params.DoctorID)
                    this.$router.go(-1)
                else
                   this.$emit('close')

                    this.$vs.notify({
                  title: this.$t("success"),
                  text: res.data.Message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "success",
                });
              }
            )
            .catch(err => {
              window.showError(err.response.data.message);
              console.error(err);
             
            });

          this.$emit("closeSidebar");
        }
      });
    },
    setRating(rating) {
      this.model.rate = rating;
    }
  },
  mounted() {
    console.log(this.data);
  },
  created() {
    debugger
    this.model.Comment = "";
    debugger;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

      if(this.$route.params.DoctorID)
      {
        this.model.DoctorID = this.$route.params.DoctorID;
        this.model.PatientReserviedSessionID = this.$route.params.PatientReserviedSessionID;
      }

      else if(this.DoctorRate.DoctorID)
      {
          this.model.DoctorID = this.DoctorRate.DoctorID
          this.model.PatientReserviedSessionID = this.DoctorRate.PatientReserviedSessionID
      }

        this.$store.dispatch("DoctorList/GetDoctor",  this.model.DoctorID).then(res => {
        this.doctor = res.data.Data;
        this.$vs.loading.close();
      });
  }
};
</script>
<style>
.vue-star-rating[data-v-fde73a0c] {
  display: block;
}
.container {
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px;
  opacity: 1;
  min-height: 547px;
  
  background: #ffffff 0% 0% no-repeat padding-box;
}
.commentClass {
  height: 8rem !important;
  background: #e6e6e6 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
  padding: 0.5rem;
}

.TextLogo {
  letter-spacing: 0px;
  font-size: 2rem;
  font-weight: bold;
  color: #004477;
}
.P-Text {
  color: var(--unnamed-color-454a62);

  font: normal normal 600 30px/31px Futura PT;
  letter-spacing: 0px;
  color: #454a62;
  padding-top: 2rem;
}
.vsButton {
  background: var(--unnamed-color-004477) 0% 0% no-repeat padding-box;
  background: #004477 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 22px #00000029;
  opacity: 1;
}
</style>
